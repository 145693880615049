import React from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

const getInitialEditorState = (initialValue) => {
  try {
    const blocksFromHtml = htmlToDraft(initialValue || '<p></p>');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    return EditorState.createWithContent(contentState);
  } catch (err) {
    console.log(`AQUI ERROR: ${err}`);
    return EditorState.createWithContent(stateFromHTML(initialValue));
  }
};

const Rendered = ({ html, ...props }) => {
  //const editorState = getInitialEditorState(html);
  //const _html = `<div className="wysiwyg" dangerouslySetInnerHTML={{ __html: "${html}" }}></div>`;
  return (
      <>
        <iframe
        className={`iframe-class ${props.className}`}
        srcDoc={html} 
        width="100%"
        height="500px"
        style={{ border: 'none' }}
        title="Iframe Title"
      ></iframe>
        {/* <Editor
          {...props}
          toolbar={{
            options: [],
          }}
          readOnly
          className={props.className}
          editorState={editorState}
        /> */}
      </>
  );
};

export default Rendered;
